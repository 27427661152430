body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: grid;
  grid-template-columns: auto auto;
}

.field {
  display: grid;
  grid-template-columns: 0.5fr auto auto;
  text-align: left;
  margin: 0.5rem;
  grid-gap: 1rem;
}

.cropped {
  min-height: 200px;
  border: 2px solid #666666;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 2rem;
  /* justify-content: center; */
}

.wrapper {
  text-align: center;
}

