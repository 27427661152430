.App {
  display: grid;
  grid-template-columns: auto auto;
}

.field {
  display: grid;
  grid-template-columns: 0.5fr auto auto;
  text-align: left;
  margin: 0.5rem;
  grid-gap: 1rem;
}

.cropped {
  min-height: 200px;
  border: 2px solid #666666;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  /* justify-content: center; */
}

.wrapper {
  text-align: center;
}
